import short from "short-uuid";

const prepositions = [
  "above",
  "across",
  "against",
  "along",
  "among",
  "around",
  "at",
  "before",
  "behind",
  "below",
  "beneath",
  "beside",
  "between",
  "by",
  "down",
  "from",
  "in",
  "into",
  "near",
  "of",
  "off",
  "on",
  "to",
  "toward",
  "under",
  "upon",
  "with",
  "within",
];

export const parsePubmedArticleTitle = (title = "") => {
  const temp = title.split("\n");
  return temp[temp.length - 1];
};

export const autoHighlightKeyword = (text = "", keyword = "") => {
  const keywordToHighlight = keyword
    .split(" ")
    .map((e) => e.trim().toLocaleLowerCase())
    .filter((e) => !prepositions.includes(e))
    .filter((e) => e);
  if (keywordToHighlight.length > 0) {
    return text
      .split(" ")
      .map((e) => {
        const temp = e.trim().toLocaleLowerCase();
        if (keywordToHighlight.some((m) => temp.includes(m))) {
          return `<strong>${e}</strong>`;
        }
        return e;
      })
      .join(" ");
  }
  return text;
};

export const firstCapitalize = (s?: string) => {
  if (!s) return "";
  const arr = s.split(/\b/);
  return arr.map((a) => `${a?.charAt(0)?.toUpperCase() + a.slice(1)}`).join("");
};

export const shortUUID = (uuid?: string) => {
  if (uuid) {
    const translator = short();
    return translator.fromUUID(uuid);
  }
};

export const restoreUUID = (s?: string) => {
  if (s) {
    const translator = short();
    return translator.toUUID(s);
  }
};
